import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Button, Dropdown, Table, Text } from 'tabler-react'
import useReactRouter from 'use-react-router'
import { EventStoreContext } from '../../contexts/eventStoreContext'
import { Types } from '../../types/graphql'
import StudentLink from './StudentLink'

interface StudentRegistrationListItemProps {
  student: Types.RegisteredStudents
}

const StudentRegistrationListItem = ({
  student
}: StudentRegistrationListItemProps) => {
  const eventStore = useContext(EventStoreContext)
  const { history } = useReactRouter()

  const routeToCalendar = (student) => {
    eventStore.addEvent({
      auto_approval: student.auto_approval,
      camp_description: student.camp_description,
      camp_email: student.camp_email,
      camp_name: student.camp_name,
      camp_phone: student.camp_phone,
      camp_url: student.camp_url,
      camp_fees: student.camp_fees,
      end: student.end,
      event_type_name: student.event_type_name,
      id: student.camp_id,
      is_public: student.is_public,
      location: student.location,
      title: undefined,
      start: student.start,
      status: student.status,
      location_id: student.location_id
    })
    history.push(`/user/calendar/${student.camp_id}`)
  }

  return (
    <>
      <Table.Col>
        <Text RootComponent="span" muted>
          <Link
            to={`/events/registrations/${student.user_registrations_id}/${student.student_id}`}
          >
            {student.user_registrations_id}
          </Link>
        </Text>
      </Table.Col>
      <Table.Col>
        <StudentLink
          studentId={Number(student.student_id)}
          studentName={`${student.first_name} ${student.last_name}`}
        />
      </Table.Col>
      <Table.Col>
        <Link
          className="text-inherit"
          onClick={(e) => {
            e.preventDefault()
            routeToCalendar(student)
          }}
          to="#"
        >
          {student.camp_name}
        </Link>
      </Table.Col>
      <Table.Col>{student.registration_code}</Table.Col>
      <Table.Col>{new Date(student.created_on).toLocaleDateString()}</Table.Col>
      <Table.Col>
        <React.Fragment>
          <Button.Dropdown value="Actions" color="secondary" size="sm">
            <Dropdown.Item>View</Dropdown.Item>
            <Dropdown.Item>Update</Dropdown.Item>
          </Button.Dropdown>
        </React.Fragment>
      </Table.Col>
    </>
  )
}

export default StudentRegistrationListItem
