import { useQuery } from '@apollo/client'
import { observer } from 'mobx-react'
import React, { useMemo, useState } from 'react'
import Select from 'react-select'
import { GET_REGISTERED_STUDENTS } from '../../../../graphql/GET_REGISTERED_STUDENTS'
import { GET_REGISTERED_STUDENTS as GET_REGISTERED_STUDENTS_TYPE } from '../../../../graphql/types/GET_REGISTERED_STUDENTS'
import { Types } from '../../../../types/graphql'

const SEARCH_LIMIT = 10

interface CampParticipantSearchFieldProps {
  onChange: any
  defaultValue: string
  isDisabled?: boolean
  autoFocus?: boolean
  campId: number
  status?: string
}

const CampParticipantSearchField = ({
  onChange,
  defaultValue,
  isDisabled,
  autoFocus,
  campId,
  status
}: CampParticipantSearchFieldProps) => {
  const [selectedMember, setSelectedMemeber] = useState(defaultValue)

  const { loading, data, error } = useQuery<GET_REGISTERED_STUDENTS_TYPE>(
    GET_REGISTERED_STUDENTS,
    {
      variables: {
        campId: campId,
        filter: status
      },
      fetchPolicy: 'network-only'
    }
  )

  const studentOptions = useMemo(() => {
    if (!loading && data) {
      return data[Object.keys(data)[0]].map(
        (student: Types.RegisteredStudents) => ({
          label: `${student.first_name} ${student.last_name}`,
          value: student.student_id,
          user_registrations_id: student.user_registrations_id
        })
      )
    }
    return []
  }, [data, loading])

  return (
    <Select
      options={studentOptions}
      onChange={(e) => {
        onChange(e)
        setSelectedMemeber(e.value)
      }}
      value={studentOptions.find((member) => selectedMember === member.value)}
    />
  )
}

export default observer(CampParticipantSearchField)
