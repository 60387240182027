import moment from 'moment'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Avatar, Button, Grid, Text } from 'tabler-react'
import useReactRouter from 'use-react-router'
import { useRootStore } from '../../hooks'
import useGetCurrentUserType from '../../modules/common/hooks/useGetCurrentUserType'
import { Types } from '../../types/graphql'

interface IRegisteredStudentsListItemProps {
  registeredStudent: Types.RegisteredStudents
}

const RegisteredStudentsListItem = ({
  registeredStudent
}: IRegisteredStudentsListItemProps) => {
  const { history } = useReactRouter()
  const { isStudent } = useGetCurrentUserType()
  const { currentUser } = useRootStore()
  const redirectLink = useMemo(() => {
    if (!isStudent) {
      return `/events/registrations/${registeredStudent.user_registrations_id}/${registeredStudent.student_id}`
    }

    if (isStudent && Number(registeredStudent.student_id) === currentUser.id) {
      return `/events/registrations/${registeredStudent.user_registrations_id}/${registeredStudent.student_id}`
    }
    return `#`
  }, [isStudent, currentUser, registeredStudent])
  return (
    <li
      className="list-separated-item cursor-pointer"
      onClick={() => history.push(redirectLink)}
    >
      <Grid.Row className="align-items-center">
        <Grid.Col auto>
          <Avatar
            size="md"
            className="d-block cursor-pointer"
            status={registeredStudent.status_color ?? 'danger'}
            imageURL={
              (registeredStudent.profile_avatar &&
                `/api/s3/uploads/${registeredStudent.profile_avatar}`) ||
              '//www.gravatar.com/avatar?d=mp'
            }
            onClick={() => history.push(redirectLink)}
          />
        </Grid.Col>
        <Grid.Col>
          <Link className="text-inherit mr-5" to={redirectLink}>
            {registeredStudent.first_name} {registeredStudent.last_name}
            <Button
              icon={registeredStudent.status_icon ?? 'x-circle'}
              color="white"
              size="sm"
              className={`float-right cursor-pointer ${
                registeredStudent.status_color
                  ? 'text-' + registeredStudent.status_color
                  : 'text-danger'
              }`}
            >
              {registeredStudent.status === 'Pending' &&
                registeredStudent.status}
            </Button>
          </Link>
          <Text.Small muted className="d-block item-except h-1x">
            {moment(registeredStudent.created_on).format('MMM D, YYYY')}
          </Text.Small>
        </Grid.Col>
      </Grid.Row>
    </li>
  )
}

export default RegisteredStudentsListItem
